import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Grid, Divider } from '@mui/material';
import { BatteryChargingFull, Speed, EvStation, Navigation, Map } from '@mui/icons-material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from 'react-router-dom';
import MapComponent from './MapComponent'; 
import mqtt from 'mqtt';

function VehiclePage({ vehicleName }) {
  const navigate = useNavigate();
  const [coordinates, setCoordinates] = useState({ 
                                                  latitude: null, 
                                                  longitude: null });

  const [VehicleInfo , setVehicleInfo] = useState({
                                                  batterySoC: null, 
                                                  batteryVoltage: null, 
                                                  currentVelocity: null,
                                                  setVelocity: null,
                                                  steeringAngle: null,
                                                  setSteeringAngle: null,
                                                  emergencyStatus: null,
                                                  actualDirection: null,
                                                  setDirection: null,
                                                  controller: null});

  useEffect(() => {
    
    const options = {
      clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`,
      username: 'robeffMaster1',
      password: 'robeffMaster1',
      protocol: 'mqtt',
      port: 8080
    };

    console.log(localStorage.getItem("Chosen Vehicle"));

    const client = mqtt.connect('wss://mqtt.robeff.com', options);

    client.on('connect', () => {
      console.log('Connected to MQTT broker');

	  const nav_msg_topic = `/${localStorage.getItem("Chosen Vehicle")}/nav_msg`;
	  const vehicle_msg_topic = `/${localStorage.getItem("Chosen Vehicle")}/vehicle_msg`;

      client.subscribe([nav_msg_topic, vehicle_msg_topic], (err) => {
        if (err) {
          console.error('Failed to subscribe:', err);
        } else {
          console.log('Subscribed to topics');
        }
      });
    });

    client.on('message', (topic, message) => {
      console.log(`Message received on topic ${topic}:`, message.toString());
      
      if (topic === `/${localStorage.getItem("Chosen Vehicle")}/nav_msg`) {

        const buffer = Buffer.from(message);
        const arrayBuffer = buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength);
        const dataView = new DataView(arrayBuffer);
        
        const latitude = dataView.getFloat32(0, true); 
        const longitude = dataView.getFloat32(4, true);

        setCoordinates({ latitude, longitude });
      }

      if (topic === `/${localStorage.getItem("Chosen Vehicle")}/vehicle_msg`) {
        
        const buffer = Buffer.from(message);
        const arrayBuffer = buffer.buffer.slice(buffer.byteOffset, buffer.byteOffset + buffer.byteLength);
        const dataView = new DataView(arrayBuffer);

        const batteryVoltage = dataView.getUint16(0, true);   
        const soc = dataView.getUint8(2, true);                    
        const velocity = dataView.getInt16(3, true);         
        const steeringAngle = dataView.getUint16(5, true);   
        const setVelocity = dataView.getInt16(7, true);      
        const setSteeringAngle = dataView.getUint16(9, true); 
        const emergencyStatus = dataView.getUint8(11, true);       
        const actualDirection = dataView.getUint8(12, true);       
        const setDirection = dataView.getUint8(13, true);          
        const controller = dataView.getUint8(14, true);            

        setVehicleInfo({
          batteryVoltage,
          velocity,
          setVelocity,
          steeringAngle,
          setSteeringAngle,
          soc,
          emergencyStatus,
          actualDirection,
          setDirection,
          controller
        });
      }
    });

    
    return () => {
      if (client) {
        client.end();
      }
    };
  }, [vehicleName]);
  

  const handleCameraClick = () => {
    navigate(`/admin/${localStorage.getItem("Chosen Vehicle")}/camera`);
  };


  return (
    <Box marginTop={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button variant="contained" color="primary" onClick={handleCameraClick}>
          Camera
        </Button>
      </Box>

      <Paper elevation={3} style={{ 
        padding: '16px', 
        borderRadius: '16px',
        marginLeft: '16px',
        marginRight: '16px' }}>
        <Typography variant="h6" gutterBottom>
          {vehicleName} Vehicle Information Panel
        </Typography>

        {/* Grid layout for two columns */}
        <Grid container spacing={2}>
          {/* Left Column */}
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">

              {/* Battery SoC */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px">
                <BatteryChargingFull />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Battery SoC
                </Typography>
              </Box>

              {/* Battery Voltage */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <EvStation />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Battery Voltage
                </Typography>
              </Box>

              {/* Current Velocity */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <Speed />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Current Velocity
                </Typography>
              </Box>

              {/* Set Velocity */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <Speed />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Set Velocity
                </Typography>
              </Box>

              {/* Current Steering Angle */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <Navigation />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Current Steering Angle
                </Typography>
              </Box>

              {/* Set Steering Angle */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <Navigation />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Set Steering Angle
                </Typography>
              </Box>

              {/* Emergency Status */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <NotificationsActiveIcon style={{ color: 'black' }} />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Emergency Status
                </Typography>
              </Box>

              {/* AActual Direction */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <NotificationsActiveIcon style={{ color: 'black' }} />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Actual Direction
                </Typography>
              </Box>

              {/* Set Direction */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <NotificationsActiveIcon style={{ color: 'black' }} />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Set Direction
                </Typography>
              </Box>

              {/* Controller */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <NotificationsActiveIcon style={{ color: 'black' }} />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Controller
                </Typography>
              </Box>

              {/* Location */}
              <Box display="flex" alignItems="center" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                <Map style={{ color: 'black' }} />
                <Divider orientation="vertical" flexItem sx={{ margin: '0 8px' }} />
                <Typography variant="body1" color="textSecondary">
                  Location
                </Typography>
              </Box>


                <Box marginTop={2} height="400px">
                  <MapComponent latitude={coordinates.latitude} longitude={coordinates.longitude} />
                </Box>
              </Box> 
        </Grid>

          {/* Right Column */}
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" justifyContent="space-between">
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px">
                {VehicleInfo.batterySoC ? `${VehicleInfo.batterySoC} %` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.batteryVoltage ? `${VehicleInfo.batteryVoltage} V` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.currentVelocity ? `${VehicleInfo.currentVelocity} km/h` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.setVelocity ? `${VehicleInfo.setVelocity} km/h` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.steeringAngle ? `${VehicleInfo.steeringAngle}°` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.setSteeringAngle ? `${VehicleInfo.setSteeringAngle}°` : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.emergencyStatus !== null
                  ? VehicleInfo.emergencyStatus === 0 ? 'No Emergency' : 'Emergency'
                  : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.actualDirection !== null 
                  ? VehicleInfo.actualDirection === 0 ? 'Reverse' : VehicleInfo.actualDirection === 1 ? 'Forward' : VehicleInfo.actualDirection === 2 ? 'None'
                  : 'N/A' : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.setDirection !== null 
                  ? VehicleInfo.setDirection === 0 ? 'Reverse' : VehicleInfo.setDirection === 1 ? 'Forward' : VehicleInfo.setDirection === 2 ? 'None'
                  : 'N/A' : 'N/A'}
              </Typography>
              <Typography variant="body1" padding="8px" border="1px solid grey" borderRadius="8px" marginTop={2}>
                {VehicleInfo.controller !== null 
                  ? VehicleInfo.controller === 0 ? 'None' : VehicleInfo.controller === 1 ? 'Remote' : VehicleInfo.controller === 2 ? 'Autoware'
                  : 'N/A' : 'N/A'}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* WebSocket decoded data display */}
        <Box marginTop={3}>
          <Typography variant="body2" color="textSecondary">
            Coordinates:
          </Typography>
          <Typography variant="body1">
            {coordinates.latitude !== null && coordinates.longitude !== null
              ? `Latitude: ${coordinates.latitude}, Longitude: ${coordinates.longitude}`
              : 'No data received yet.'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
}

export default VehiclePage;