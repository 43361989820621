import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import VehiclePage from './VehiclePage';
import AdminPage from './AdminPage';
import SignUpPage from './SignUpPage';
import './App.css'; 
import SignInPage from './SignInPage';
import VehicleCameraPage from './VehicleCameraPage';

function App() {
  
  localStorage.setItem('user123', JSON.stringify(['Integrated Camera', 'Iriun Webcam']));
    
  return (
    <div>
      <Router>
        <Routes>
            <Route path="/" element={<SignInPage />} />
            <Route path="/vehiclePage" element={<VehiclePage />} />
            <Route path="/adminPage" element={<AdminPage />} />
            <Route path="/signUpPage" element={<SignUpPage />} />
            <Route path="/admin/:vehicleName" element={<VehiclePage />} />
            <Route path="/admin/:vehicleName/camera" element={<VehicleCameraPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;