import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Typography, List, ListItem, ListItemText, Divider, Card, CardContent} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ReactSpeedometer from "react-d3-speedometer";
import SteeringWheel from './SteeringWheel'; 
import Direction from './Direction';
import ElectricalCarIcon from '@mui/icons-material/ElectricCar';
import { useNavigate } from 'react-router-dom';

const DashboardContainer = styled(Box)`
  display: flex;
  height: 100vh;
  overflow: hidden;
  border: 1px solid #ddd; 
`;

const Sidebar = styled(Box)`
  width: 20%;
  padding: 20px;
  background-color: #175E81;
  color: white;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #35D3C9; 
`;

const Content = styled(Box)`
  width: 100%;
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px; 
`;

const Section = styled(Box)`
  flex: 1;
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: scroll; 
`;

const SectionHeaderCard = styled(Card)`
  width: 25%;
  height: 100%;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
`;

const SectionContent = styled('div')`
  padding-left: 30px; 
`;


const CustomListItem = styled(ListItem)(({ selected }) => ({
  position: 'relative',
  backgroundColor: selected ? '#1e88e5' : 'transparent', 
  color: selected ? 'white' : 'gray', 
  paddingLeft: '20px',
  paddingRight: '40px',
  marginBottom: '10px',
  transition: selected ? 'background-color 0.3s ease, color 0.3s ease' : 'none',
  borderRadius: '12px', 
  '&:hover': {
    backgroundColor: selected ? '#1e88e5' : '#1565c0', 
    cursor: 'pointer',
    color: 'white',
  },
  '& .arrow-icon': {
    transform: selected ? 'translateX(5px)' : 'translateX(0)',
    opacity: selected ? 1 : 0,
    transition: selected ? 'transform 0.3s ease, opacity 0.3s ease' : 'none',
  },
  '& .MuiListItemText-root': {
    textAlign: 'left',
  },
}));

const ArrowIcon = styled(ArrowForwardIosIcon)({
  position: 'absolute',
  right: '10px',
  top: '25%',
  transform: 'translateY(-50%)', 
  opacity: 0,
  transition: 'transform 0.3s ease, opacity 0.3s ease',
  color: 'white',
});


const AdminPage = () => {
  const [vehicles, setVehicles] = useState([]); 
  const [error, setError] = useState(''); 
  const [openVehicle, setOpenVehicle] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null); 
  const navigate = useNavigate();


  useEffect(() => {

    const fetchVehicles = async () => {

      const token = localStorage.getItem('access_token');
      console.log(token);

      const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      };
      
      try {
          const response = await fetch('https://test-api.robeff.com/operation/vehicles', {
              method: 'GET',
              headers: headers,
          });
          
          if (response.ok) {
              const data = await response.json();
              console.log(data);
            
              setVehicles(JSON.parse(data.vehicles.replace(/'/g, '"'))); 
              console.log(data);
              console.log(vehicles);
              console.log(typeof data);
          } else {

              setError('Failed to fetch vehicles data.');
          }
      } catch (err) {

          setError('An error occurred while fetching vehicles data.');
      }
    };

    fetchVehicles();
    //getDevices();

  }, [vehicles]);


  const handleVehicleClick = (vehicle) => {   
    
    localStorage.setItem("Chosen Vehicle", vehicle);
    navigate(`/admin/${vehicle}`);

    setSelectedVehicle(vehicle); // Yeni araç seçildiğinde state'i günceller
    setOpenVehicle(openVehicle === vehicle ? null : vehicle); // Toggle the selected vehicle
  };
  
  const getRandomValue = () => Math.floor(Math.random() * 100);

return (
  <DashboardContainer>  
    <Sidebar>
      <Box display="flex" alignItems="center" gap={1}>
        <ElectricalCarIcon fontSize="large" />
        <Typography variant="h4" gutterBottom>Vehicles</Typography>
      </Box>
      <Divider style={{ marginBottom: '20px', backgroundColor: 'white' }} />
      <List>
        {vehicles.length > 0 ? (
          vehicles.map((vehicle, index) => (
            <CustomListItem button 
              key={index} 
              selected={selectedVehicle === vehicle}
              onClick={() => handleVehicleClick(vehicle)}>
                <ListItemText primary={vehicle} />
                <ArrowIcon className="arrow-icon" />
            </CustomListItem>
          ))
        ) : (
          <Typography>No vehicles available.</Typography>
        )}
      </List>
    </Sidebar>

    <Content>
      <Section>
        <SectionHeaderCard>
          <CardContent>
            <Typography variant="h6">Vehicle Camera</Typography>
          </CardContent>
        </SectionHeaderCard>
      </Section>


      <Section sx={{ height: '100%' }}>
        <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            width="100%"
            height="90%"
            padding={2}
            gap={3} 
        >
          <Box 
            display="flex" 
            flexDirection="column" 
            flex={1} 
            gap={2}
            width="50px" 
            padding={2}
            border="1px solid white" 
            borderRadius="8px">
            
            <Box
              paddingX={0.5}               
              paddingY={0.5}             
              border="1px solid #ccc"   
              borderRadius="8px"         
              bgcolor="transparent"      
              marginBottom={1}           
              marginTop={-5}
            >
              <Typography 
                variant="body2"          
                align="center"
                color="black"
              >
                Vehicle Controller
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="center"      
              alignItems="center"          
              marginTop="-55px"            
              marginLeft="50px"            
            >
              <SteeringWheel />
            </Box>
          </Box>

          <Box 
            display="flex" 
            flexDirection="column" 
            flex={1} 
            gap={2}
            width="50px" 
            padding={2}
            border="1px solid white" 
            borderRadius="8px">
            
            <Box
              paddingX={0.5}               
              paddingY={0.5}             
              border="1px solid #ccc"   
              borderRadius="8px"        
              bgcolor="transparent"      
              marginBottom={1}           
              marginTop={-5}
            > 

              <Typography 
                variant="body2"         
                align="center"
                color="black"
              >
                Vehicle Speed
              </Typography>
            </Box>

            <Box
              width="50%"
              height="80%"
              display="flex"
              justifyContent="center"      
              alignItems="center"          
              marginTop="80px"            
              marginLeft="70px"            
            >
              <ReactSpeedometer
                value={getRandomValue()}
                maxValue={100}
                needleColor="steelblue"
                startColor="green"
                endColor="red"
                textColor="black"
              />
            </Box>
          </Box>

          <Box 
            display="flex" 
            flexDirection="column" 
            flex={1} 
            gap={2}
            width="50px" 
            padding={2}
            border="1px soli d white" 
            borderRadius="8px">
            
            <Box
              paddingX={0.5}               
              paddingY={0.5}             
              border="1px solid #ccc"   
              borderRadius="8px"         
              bgcolor="transparent"     
              marginBottom={1}           
              marginTop={-5}
            >
              <Typography 
                variant="body2"          
                align="center"
                color="black"
              >
                Vehicle Direction
              </Typography>
            </Box>

            <Box
              display="flex"
              justifyContent="center"      
              alignItems="center"          
              marginTop="60px"            
              marginLeft="35px"           
            >
              <Direction />
            </Box>
          </Box>
    </Box>
        <SectionContent>
          {error && <Typography color="error">{error}</Typography>}
        </SectionContent>
      </Section>
    </Content>
  </DashboardContainer>
);


};

export default AdminPage;