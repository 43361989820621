import React, { useState, useEffect } from 'react';
import './App.css'; // CSS dosyanız için

function Direction() {
  const [speed, setSpeed] = useState(0);
  const [showForwardImage, setShowForwardImage] = useState(false);
  const [showBackwardImage, setShowBackwardImage] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomSpeed = Math.floor(Math.random() * 20) - 10; // -10 ile 10 arasında rastgele hız
      setSpeed(randomSpeed);

      if (randomSpeed > 0) {
        setShowForwardImage(true);
        setShowBackwardImage(false);
      } else if (randomSpeed < 0) {
        setShowForwardImage(false);
        setShowBackwardImage(true);
      } else {
        setShowForwardImage(false);
        setShowBackwardImage(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  /*
  return (
    <div className="App">
      <div className="car">
        {showForwardImage && <img src="/Forward.png" alt="Forward" />}
        {showBackwardImage && <img src="/Backward.png" alt="Backward" />}
      </div>
      <p>Hız: {speed} km/h</p>
    </div>
  );
  */

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <div style={{ marginBottom: '10px' }}>
        {showForwardImage && <img src="/Forward.png" alt="Forward" style={{ width: '130px', height: '130px', marginTop: '-10px' }} />}
        {showBackwardImage && <img src="/Backward.png" alt="Backward" style={{ width: '130px', height: '130px', marginTop: '-10px' }} />}
      </div>
      <p style={{ fontSize: '16px', color: 'black' }}>Hız: {speed} km/h</p>
    </div>
  );
}

export default Direction;

/*
import React, { useState, useEffect } from 'react';
import './App.css'; // CSS dosyanız için

function Direction() {
  const [speed, setSpeed] = useState(0);
  const [sensorColor, setSensorColor] = useState('black');
  const [showForwardImage, setShowForwardImage] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomSpeed = Math.floor(Math.random() * 20) - 10; // -10 ile 10 arasında rastgele hız
      setSpeed(randomSpeed);

      if (randomSpeed > 0) {
        setSensorColor('green');
        setShowForwardImage(true);
      } else if (randomSpeed < 0) {
        setSensorColor('red');
        setShowForwardImage(false);
      } else {
        setSensorColor('black');
        setShowForwardImage(false);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <div className="car">
        <div className="sensor" style={{ backgroundColor: sensorColor }}></div>
        {showForwardImage && <img src="/Forward.png" alt="Forward" />}
        <img src="/ForwardBackwardSymbols.png" alt="Car" />
        <div className="sensor" style={{ backgroundColor: sensorColor }}></div>
      </div>
      <p>Hız: {speed} km/h</p>
    </div>
  );
}

export default Direction;
*/

/*
import React, { useState, useEffect } from 'react';
import './App.css'; // CSS dosyanız için

function Direction() {
  const [speed, setSpeed] = useState(0);
  const [frontSensorColor, setFrontSensorColor] = useState('black');
  const [rearSensorColor, setRearSensorColor] = useState('black');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomSpeed = Math.floor(Math.random() * 20) - 10; // -10 ile 10 arasında rastgele hız
      setSpeed(randomSpeed);

      if (randomSpeed > 0) {
        setFrontSensorColor('green');
        setRearSensorColor('black');
      } else if (randomSpeed < 0) {
        setFrontSensorColor('black');
        setRearSensorColor('red');
      } else {
        setFrontSensorColor('black');
        setRearSensorColor('black');
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="Direction">
      <div className="car">
        <div className="sensor front" style={{ backgroundColor: frontSensorColor }}></div>
        <img src="/ForwardBackwardSymbols.png" alt="Car" />
        <div className="sensor rear" style={{ backgroundColor: rearSensorColor }}></div>
      </div>
      <p>Hız: {speed} km/h</p>
    </div>
  );
}

export default Direction;
*/

/*
import React, { useState, useEffect } from 'react';
import './App.css'; // CSS dosyanız için

function Direction() {
  const [speed, setSpeed] = useState(0);
  const [sensorColor, setSensorColor] = useState('black');

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomSpeed = Math.floor(Math.random() * 20) - 10; // -10 ile 10 arasında rastgele hız
      setSpeed(randomSpeed);

      if (randomSpeed > 0) {
        setSensorColor('green'); // Pozitif hızda yeşil
      } else if (randomSpeed < 0) {
        setSensorColor('red'); // Negatif hızda kırmızı
      } else {
        setSensorColor('black'); // Hız sıfır ise siyah
      }
    }, 1000); // Her saniye bir güncelleme

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <div className="car">
        <div className="sensor" style={{ backgroundColor: sensorColor }}></div>
        <img src="/ForwardBackwardSymbols.png" alt="Car" />
        <div className="sensor" style={{ backgroundColor: sensorColor }}></div>
      </div>
      <p>Hız: {speed} km/h</p>
    </div>
  );
}

export default Direction;
*/