import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Select, MenuItem, InputLabel, FormControl, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import ReactSpeedometer from "react-d3-speedometer"; 
import SteeringWheel from './SteeringWheel';
import Direction from './Direction'; 
import Draggable from 'react-draggable'; 
import './VehicleCameraPage.css'; 
import SpeedIcon from '@mui/icons-material/Speed';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import NavigationIcon from '@mui/icons-material/Navigation';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
//import MQTTClient from 'mqtt'; 

function VehicleCameraPage() {
  const videoRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const [controllers, setControllers] = useState([]); 
  const [dialogOpen, setDialogOpen] = useState(false);


  async function getCameraDevices() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      setCameraDevices(videoDevices);

      const iriunCamera = videoDevices.find(device => device.label.includes('Iriun Webcam'));
      if (iriunCamera) {
        setSelectedDeviceId(iriunCamera.deviceId);
      } else if (videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId);
      }
    } catch (err) {
      console.error('Kamera cihazları listelenemedi:', err);
    }
  }

  async function startCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined }
      });
      videoRef.current.srcObject = stream;
      setIsStreaming(true);
    } catch (err) {
      console.error('Kamera başlatılamadı:', err);
      alert('Kamera başlatılamadı. Lütfen izinleri kontrol edin veya başka bir cihaz seçin.');
    }
  }

  function stopCamera() {
    const stream = videoRef.current.srcObject;
    if (stream) {
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.current.srcObject = null;
    }
    setIsStreaming(false);
  }

  useEffect(() => {
    getCameraDevices();
  }, []);

  useEffect(() => {
    return () => {
      if (isStreaming) {
        stopCamera();
      }
    };
  }, [isStreaming]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      stopCamera();
    };

    window.addEventListener('popstate', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handleBeforeUnload);
    };
  });

  const getRandomValue = () => Math.floor(Math.random() * 100); // For demo purposes


  const getUSBControllers = async () => {
      try {
        const devices = await navigator.usb.getDevices();

        if (Array.isArray(devices)) {
          const deviceNames = devices.map(device => device.productName || 'Unknown Device Name');
          setControllers(deviceNames); // `controllers` dizisini güncelle
        } else {
          console.error('Devices are not in the expected format:', devices);
          setControllers(['No devices found']); // Dizi değilse varsayılan değerler ekle
        }
      } catch (error) {
        console.error('Error retrieving USB controllers:', error);
        setControllers(['Error retrieving devices']); // Hata durumunda varsayılan değerler ekle
      }
        /*
        const deviceNames = devices.map(device => device.productName || 'Unknown Device Name');
        setControllers(deviceNames);
      } catch (error) {
        console.error('Error retrieving USB controllers:', error);
        setControllers(['Error retrieving devices']);
      }
        */
    };
    
    const handleOpenDialog = () => {
      setControllers(getUSBControllers());
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
    };
  
    const handleSelectController = (controller) => {
      //setSelectedController(controller);
      //mqttClient.publish('controller/topic', '2'); // Publish data to MQTT topic
      handleCloseDialog();
    };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: isStreaming ? 'black' : '#f0f0f0',
        overflow: 'hidden'
      }}
    >

      <video
        ref={videoRef}
        autoPlay
        style={{
          display: isStreaming ? 'block' : 'none',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
        }}
      />

      {!isStreaming && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.7)',
            zIndex: 1
          }}
        >
          <Typography variant="h3" color="white" fontWeight="bold">
            No Live Streaming
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
          textAlign: 'center',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={isStreaming ? stopCamera : startCamera}
          sx={{ mb: 2 }}
        >
          {isStreaming ? 'Kamerayı Durdur' : 'Kamerayı Başlat'}
        </Button>

        {isStreaming && (
          <FormControl variant="filled" sx={{ minWidth: 200 }}>
            <InputLabel id="camera-select-label" sx={{ color: 'white' }}>Mevcut Kameralar</InputLabel>
            <Select
              labelId="camera-select-label"
              value={selectedDeviceId || ''}
              onChange={(e) => setSelectedDeviceId(e.target.value)}
              sx={{ color: 'white' }}
            >
              {cameraDevices.map(device => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Kamera ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>


      {isStreaming && (
        <Draggable>
          <Box
          sx={{
            position: 'absolute',
            bottom: '100px',
            left: '100px',
            width: '800px',  
            height: '230px',  
            bgcolor: 'white',
            padding: 2,
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'row', 
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 3,
          }}
        >
          
        {/* Vehicle Controller */}
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center',
          bgcolor: 'white',
          padding: 2,
          borderRadius: '8px',
          marginBottom: '2px',
          marginTop: '2px',
          border: '2px solid black',
          width: '250px', 
          height: '200px', 
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          margin: '0 5px',  
        }}
      >
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row', 
          alignItems: 'center',
          justifyContent: 'center', 
          width: '100%',
          borderBottom: '2px solid black',
          paddingBottom: '8px',
          marginBottom: '8px',
        }}
      >
        <DirectionsCarIcon sx={{ fontSize: 40, color: 'black', marginRight: '16px' }} />
        <Typography
          variant="h6"
          color="black"
          sx={{
            fontSize: '16px',
            borderLeft: '2px solid black',
            paddingLeft: '8px'
          }}
        >
          Vehicle Controller
        </Typography>
        </Box>

    <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
      <SteeringWheel style={{ width: '100px', height: '100px' }} /> {/* Boyutlar artırıldı */}
    </Box>
  </Box>


  {/* Vehicle Speed */}
  <Box
  sx={{
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center',
    bgcolor: 'white',
    padding: 2,
    borderRadius: '8px',
    marginBottom: '2px',
    marginTop: '2px',
    border: '2px solid black',
    width: '250px', 
    height: '200px', 
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    margin: '0 5px',  
  }}
  >
      
  <Box
  sx={{
    display: 'flex',
    flexDirection: 'row', 
    alignItems: 'center',
    justifyContent: 'center', 
    width: '100%',
    borderBottom: '2px solid black',
    paddingBottom: '8px',
    marginBottom: '8px',
  }}
  >
  <SpeedIcon sx={{ fontSize: 40, color: 'black', marginRight: '16px' }} />
  <Typography
      variant="h6"
      color="black"
      sx={{
        fontSize: '16px',
        borderLeft: '2px solid black',
        paddingLeft: '8px'
      }}
  >
    Vehicle Speed
  </Typography>
  </Box>

    <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
      <ReactSpeedometer
        value={getRandomValue()}
         maxValue={100}
        needleColor="steelblue"
        startColor="green"
        endColor="red"
        textColor="black"
        width={200} 
        height={180} 
      />
    </Box>
  </Box>

  {/* Vehicle Direction */}
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', 
          alignItems: 'center',
          bgcolor: 'white',
          padding: 2,
          borderRadius: '8px',
          marginBottom: '2px',
          marginTop: '2px',
          border: '2px solid black',
          width: '250px', 
          height: '200px', 
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          margin: '0 5px', 
        }}
      >
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row', 
          alignItems: 'center',
          justifyContent: 'center', 
          width: '100%',
          borderBottom: '2px solid black',
          paddingBottom: '8px',
          marginBottom: '8px',
        }}
      >
        <NavigationIcon sx={{ fontSize: 40, color: 'black', marginRight: '16px' }} />
        <Typography
          variant="h6"
          color="black"
          sx={{
            fontSize: '16px',
            borderLeft: '2px solid black',
            paddingLeft: '8px'
          }}
        >
          Vehicle Direction
        </Typography>
        </Box>

    <Box display="flex" justifyContent="center" alignItems="center" marginTop={1}>
      <Direction style={{ width: '100px', height: '100px' }} /> {/* Boyutlar artırıldı */}
    </Box>

    <IconButton
      onClick={handleOpenDialog}
      sx={{ ml: 2 }}
      >
      <ControlCameraIcon sx={{ fontSize: 30, color: 'black' }} />
    </IconButton>
    </Box>
  </Box>
</Draggable>
)}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>USB PC Controller'ları</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {controllers.map((controller, index) => (
              <Button
                key={index}
                onClick={() => handleSelectController(controller)}
                sx={{ marginBottom: 1 }}
              >
                {controller}
              </Button>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Kapat</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default VehicleCameraPage;