import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

const SteeringWheel = () => {
  const [angle, setAngle] = useState(0);
  const delay = 3000; 
  const updateInterval = 5000; 

  useEffect(() => {
    const updateAngle = () => {

      let newAngle = Math.floor(Math.random() * 360);
      
      if (newAngle > 180) {
        newAngle = newAngle % 180;
      }
      
      setAngle(newAngle);
    };


    const timeoutId = setTimeout(() => {
      updateAngle(); 


      const intervalId = setInterval(() => {
        updateAngle();
      }, updateInterval);


      return () => clearInterval(intervalId);
    }, delay);


    return () => clearTimeout(timeoutId);
  }, [delay, updateInterval]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      padding={2}
      marginTop={-2}
      border="1px solid white"
      borderRadius="2px"
      height="130px" // Height
      width="250px"  // Width
      position="relative"
      overflow="hidden"
    >
      <Box
        component="img"
        src="/SteeringWheel.png"
        alt="Steering Wheel"
        sx={{
          maxHeight: '100%',
          maxWidth: '100%',
          //maxHeight: '200px',
          //maxWidth: '100%',
          transform: `rotate(${angle}deg)`,
          transition: 'transform 0.5s ease-in-out', 
        }}
      />
    </Box>
  );
};

export default SteeringWheel;