import React, { useState } from 'react';
import axios from 'axios';
import { IconButton, InputAdornment, TextField, Button, Box, Typography, Checkbox, FormControlLabel, SvgIcon } from '@mui/material';
import { AccountCircle, Lock, Visibility, VisibilityOff, CheckCircleOutline, Business, VpnKey } from '@mui/icons-material';
import './SignUpPage.css'; 
import zxcvbn from 'zxcvbn';


const XIcon = () => (
  <SvgIcon viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="10" fill="none" stroke="red" strokeWidth="2" />
    <line x1="8" y1="8" x2="16" y2="16" stroke="red" strokeWidth="2" />
    <line x1="16" y1="8" x2="8" y2="16" stroke="red" strokeWidth="2" />
  </SvgIcon>
);

function SignUpPage () {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [secretPass, setSecretPass] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isValidLength, setIsValidLength] = useState(false);
  const [strengthColor, setStrengthColor] = useState('transparent');
  

  const validatePassword = (password) => {
      setHasUpperCase(/(?=.*[A-Z])/.test(password));
      setHasLowerCase(/(?=.*[a-z])/.test(password));
      setHasNumber(/(?=.*\d)/.test(password));
      setHasSpecialChar(/(?=.*[@$!%*?&])/.test(password));
      setIsValidLength(password.length >= 8);
  };

   const evaluatePasswordStrength = (password) => {
        const result = zxcvbn(password);
        const score = result.score; 
    
        switch (score) {
          case 0:
            setPasswordStrength('Very Weak');
            setStrengthColor('red');
            break;
          case 1:
            setPasswordStrength('Weak');
            setStrengthColor('orange');
            break;
          case 2:
            setPasswordStrength('Fair');
            setStrengthColor('yellow');
            break;
          case 3:
            setPasswordStrength('Good');
            setStrengthColor('blue');
            break;
          case 4:
            setPasswordStrength('Strong');
            setStrengthColor('green');
            break;
          default:
            setPasswordStrength('');
            setStrengthColor('transparent');
            break;
        }
    };
  
    const handlePasswordChange = (event) => {
      const newPassword = event.target.value;
      setPassword(newPassword);
      validatePassword(newPassword);
      evaluatePasswordStrength(newPassword);
      //setShowValidation(true);
    };

    const handlePasswordFocus = () => {
        setShowValidation(true);
      };
    
      const handlePasswordBlur = () => {
        setShowValidation(false);
      };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      //setError('Passwords do not match');
      return;
    }
    

    const data = {
      username,
      password,
      company_id: parseInt(companyId, 10),
      secret_pass: parseInt(secretPass, 10),
    };

    try {
      const response = await axios.post('https://test-api.robeff.com/api/signup', data);
      console.log('Response:', response.data);
    } catch (err) {
      console.error('Error:', err);
      //setError('Failed to sign up. Please try again.');
    }
  };


  return (
    <div className="signup-container"
        style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          height: '100vh', 
          padding: '0 20px',
          backgroundColor: '#2F3848' }}>
        <div className="login-content"
            style={{ 
              flex: 1, 
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'flex-start',
              marginLeft: '250px' }}>
            <div className="logo-title-container">
        <Box 
            display="flex" 
            flexDirection="row" 
            alignItems="center"
            marginBottom={3}>
            <img 
                src="/Logo.png" 
                alt="Logo" 
                style={{ 
                  width: 50, 
                  height: 50, 
                  marginRight: 10 }} 
            />
            <Typography 
                variant="h1"  
                component="h1"  
                align="center"  
                sx={{
                    fontWeight: 'bold', 
                    fontSize: '3rem',  
                    color: '#4A95C5',  
                    marginBottom: 4,  
                    marginTop: 28.5,  
                    fontFamily: 'Arial, sans-serif', 
                }}
                > CANWAY </Typography>
        </Box>
            </div>
            <div className="login-form-container" 
                   style={{ 
                        flex: 1, 
                        display: 'flex', 
                        justifyContent: 'flex-start',
                        marginLeft: '200px' }}>
            <Box 
                component="form" 
                onSubmit={handleSubmit} 
                noValidate 
                autoComplete="off"
                className="login-box" 
                display="flex" 
                flexDirection="column" 
                alignItems="center"
                justifyContent="center"
                p={4} 
                boxShadow={3}
                borderRadius={2}
                padding={2} 
                marginTop={2} 
                gap={2}
                width="650px"
                height="600px"
            >
            <Typography
                variant="h2"  
                component="h2"  
                align="center"  
                sx={{
                    fontWeight: 'bold',  
                    fontSize: '2rem',  
                    color: '#2F3848',  
                    marginBottom: 4,  
                    marginTop: 7,  
                }}
                > Create an Account </Typography>

                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    marginTop={1}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle />
                            </InputAdornment>
                        ),
                    }}
                />

                <TextField
                    label="Password"
                    type={passwordVisible ? 'text' : 'password'}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={password}
                    onChange={handlePasswordChange}
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Lock />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                >
                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

        {/* Password Strength Indicator */}
        {showValidation && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              marginTop={-3}
              width="100%"
            >
              <Box
                width="100%"
                height={10}
                borderRadius={5}
                backgroundColor="#e0e0e0"
                overflow="hidden"
                position="relative"
                marginTop={1.2}
              >
                <Box
                  height="100%"
                  width={passwordStrength === 'Strong' ? '100%' :
                         passwordStrength === 'Good' ? '80%' :
                         passwordStrength === 'Fair' ? '60%' :
                         passwordStrength === 'Weak' ? '40%' : '20%'}
                  backgroundColor={strengthColor}
                  position="absolute"
                  top={0}
                  left={0}
                  transition="width 0.3s ease"
                />
              </Box>
              <Typography variant="subtitle1" color="textSecondary">
                Password Strength: {passwordStrength}
              </Typography>
            </Box>
          )}

          {/* Validation Checkboxes */}
          {showValidation && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              marginTop={1}
              width="100%"
            >
              <FormControlLabel
                control={<Checkbox 
                            checked={isValidLength}
                            icon={<XIcon sx={{fontSize: 16}}/>}
                            checkedIcon={<CheckCircleOutline sx={{ fontSize: 26, color: 'green' }} />} 
                            sx={{ 
                              p: 0, 
                              m: 0, 
                              color: isValidLength ? 'green' : 'red', '&.Mui-checked': { color: isValidLength ? 'green' : 'red' } }} 
                         />}
                label="At least 8 characters"
                sx={{
                  marginLeft: '0.6px', 
                  marginTop: '-10px', 
                }}
              />
              <FormControlLabel
                control={<Checkbox 
                            checked={hasUpperCase}
                            icon={<XIcon sx={{fontSize: 16}}/>}
                            checkedIcon={<CheckCircleOutline sx={{ fontSize: 26, color: 'green' }} />}  
                            sx={{ 
                              p: 0, 
                              m: 0, 
                              color: hasUpperCase ? 'green' : 'red', '&.Mui-checked': { color: hasUpperCase ? 'green' : 'red' } }} />}
                label="At least 1 uppercase letter"
                sx={{
                  marginLeft: '0.6px', 
                }}
              />
              <FormControlLabel
                control={<Checkbox 
                            checked={hasLowerCase} 
                            icon={<XIcon sx={{fontSize: 16}}/>}
                            checkedIcon={<CheckCircleOutline sx={{ fontSize: 26, color: 'green' }} />} 
                            sx={{ 
                              p: 0, 
                              m: 0, 
                              color: hasLowerCase ? 'green' : 'red', '&.Mui-checked': { color: hasLowerCase ? 'green' : 'red' } }} />}
                label="At least 1 lowercase letter"
                sx={{
                  marginLeft: '0.6px', // Sola değil sağa kaydırmak için pozitif bir değer
                }}
              />
              <FormControlLabel
                control={<Checkbox 
                            checked={hasNumber}
                            icon={<XIcon sx={{fontSize: 16}}/>}
                            checkedIcon={<CheckCircleOutline sx={{ fontSize: 26, color: 'green' }} />}  
                            sx={{ 
                              p: 0, 
                              m: 0, 
                              color: hasNumber ? 'green' : 'red', '&.Mui-checked': { color: hasNumber ? 'green' : 'red' } }} />}
                label="At least 1 number"
                sx={{
                  marginLeft: '0.6px', 
                }}
              />
              <FormControlLabel
                control={<Checkbox 
                            checked={hasSpecialChar}
                            icon={<XIcon sx={{fontSize: 16}}/>}
                            checkedIcon={<CheckCircleOutline sx={{ fontSize: 26, color: 'green' }} />}  
                            sx={{ 
                              p: 0, 
                              m: 0, 
                              color: hasSpecialChar ? 'green' : 'red', '&.Mui-checked': { color: hasSpecialChar ? 'green' : 'red' } }} />}
                label="At least 1 special character"
                sx={{
                  marginLeft: '0.6px', 
                }}
              />
            </Box>
          )}

                <TextField
                        label="Confirm Password"
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CheckCircleOutline />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                                    >
                                        {confirmPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        label="Company ID"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={companyId}
                        onChange={(e) => setCompanyId(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Business />
                                </InputAdornment>
                            ),
                        }}
                    />

                    <TextField
                        label="Secret Pass"
                        variant="outlined"
                        fullWidth
                        margin="dense"
                        value={secretPass}
                        onChange={(e) => setSecretPass(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            ),
                        }}
                    />
                  
                  <Box 
                    display="flex" 
                    flexDirection="row" 
                    justifyContent="center"
                    alignItems="center" 
                    marginTop={2}
                    gap={3}
                    width="100%">
                    <Button 
                        type="submit"
                        variant="contained"
                        sx={{
                            backgroundColor: '#2F3848',
                            marginTop: -2,
                            width: '100%',
                        }}
                    > Sign Up
                    </Button>
                </Box>
            </Box>
            </div>
        </div>
    </div>
  );  
};

export default SignUpPage;