import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, TextField, Button, Box, Typography, Checkbox, FormControlLabel, LinearProgress, Alert } from '@mui/material';
import { AccountCircle, Lock, Visibility, VisibilityOff} from '@mui/icons-material';
import ElectricalCarIcon from '@mui/icons-material/ElectricCar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';


function SignInPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); 
    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    localStorage.setItem('user123', JSON.stringify(['Integrated Camera', 'Iriun Webcam']));
    
    
    const handleSignUpClick = () => {
      navigate("/signUpPage");
    };
    
    const handleAdminClick = () => {
        navigate("/adminPage");
    }

    const handleVehicleClick = () => {
        navigate("/vehiclePage");
    }

    const handleLogin = (isVehicle) => {

        setLoading(true); 
        setTimeout(() => setLoading(false), 5000); 

        const loginData = {
            username: username,
            password: password,
        };

        
        fetch('https://test-api.robeff.com/api/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('username', username);
                //setIsLoggedIn(true);

                if(rememberMe){
                    sessionStorage.setItem('token', data.access_token);
                    sessionStorage.setItem('username', data.username);
                }

                if(isVehicle){
                    handleVehicleClick();
                    sessionStorage.setItem()
                }
                else{
                    handleAdminClick();
                }

            } else {
                alert('Login failed. Please check your username and password.');
                setErrorUsername("Username doesn't exist.");
                setErrorPassword("Password doesn't exist.");
                //setIsLoggedIn(false);
            }
        })
        .catch(error => {
            setLoading(false);
            console.error('Error:', error);
            alert('An error occurred during login. Please try again.');
            setErrorUsername("Username must be filled.");
            setErrorPassword("Password must be filled.");
        });
    };
    

    return (
        <div className="login-container" 
            style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                height: '100vh', 
                padding: '0 20px',
                backgroundColor: '#2F3848' }}>
            <div className="login-content" 
                style={{ 
                    flex: 1, 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'flex-start',
                    marginLeft: '250px' }}>
                <div className="logo-title-container">
            <Box 
                display="flex" 
                flexDirection="row" 
                alignItems="center"
                marginBottom={3}
                sx={{ position: 'relative', overflow: 'hidden' }}>
                <img 
                    src="/Logo.png" 
                    alt="Logo" 
                    style={{
                        width: 50, 
                        height: 50, 
                        marginRight: 10,
                        marginTop: 200}} 
                />
                <Typography 
                    variant="h1"  
                    component="h1"  
                    align="center"  
                    sx={{
                        fontWeight: 'bold',  
                        fontSize: '3rem',  
                        color: '#4A95C5',  
                        marginBottom: 4,  
                        marginTop: 28.5,  
                        fontFamily: 'Arial, sans-serif', 
                    }}
                    > CANWAY </Typography>
            </Box>
                </div>
              <div className="login-form-container" 
                   style={{ 
                        flex: 1, 
                        display: 'flex', 
                        justifyContent: 'flex-start',
                        marginLeft: '200px' }}> 
                <Box 
                    className="signup-box" 
                    display="flex" 
                    flexDirection="column" 
                    alignItems="center"
                    justifyContent="center"
                    p={4} 
                    boxShadow={3}
                    borderRadius={2}
                    padding={2} 
                    marginTop={2} 
                    gap={2}
                    width="500px"
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        //backdropFilter: 'blur(15px)',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                        border: '1px solid rgba(255, 255, 255, 0.3)', 
                        color: '#fff', 
                    }}
                >
                <Typography
                    variant="h2"  
                    component="h2"  
                    align="center"  
                    sx={{
                        fontWeight: 'bold',  
                        fontSize: '2rem',  
                        color: '#4A95C5',  
                        marginBottom: 4,  
                        marginTop: 4,  
                    }}
                    > Sign in to your account </Typography>
                
                <Box position="relative" width="500px" marginBottom={2}>
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    />
                    {errorUsername && (
                        <Alert severity="error" sx={{ marginTop: 1, zIndex: 1 }}>
                            {errorUsername}
                        </Alert>
                    )}
                    {loading && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                backgroundColor: 'transparent', 
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: '#2F3848', 
                                },
                                zIndex: 2,
                            }}
                        />
                    )}
                </Box>

                <Box position="relative" width="500px" marginBottom={2}> 
                    <TextField
                        label="Password"
                        type={passwordVisible ? 'text' : 'password'}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                    >
                                        {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}      
                    />
                    {errorPassword && (
                        <Alert severity="error" sx={{ marginTop: 1, zIndex: 1 }}>
                            {errorPassword}
                        </Alert>
                    )}
                    {loading && (
                        <LinearProgress
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                                height: '2px',
                                backgroundColor: 'transparent', 
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: '#2F3848',
                                },
                                zIndex: 2,
                            }}
                        />
                    )}
                </Box>

                    <Box display="flex" justifyContent="flex-start">
                        <FormControlLabel
                            control={
                                <Checkbox 
                                checked={rememberMe} 
                                onChange={(e) => setRememberMe(e.target.checked)} 
                                sx={{
                                    color: '#2F3848', 
                                    '&.Mui-checked': {
                                        color: '#2F3848', 
                                    },
                                }} 
                                />
                            }
                        label="Remember me"
                        sx={{
                            color: '#000',
                            marginLeft: -32.4,
                        }} 
                        />
                    </Box>

                    <Box 
                        display="flex" 
                        flexDirection="row" 
                        justifyContent="center"
                        alignItems="center" 
                        marginTop={2}
                        gap={3}
                        width="100%">
                        <Button 
                            variant="contained"
                            startIcon={<ElectricalCarIcon />}  
                            sx={{
                                backgroundColor: '#4A95C5',
                            }}
                            onClick={() => handleLogin(true)}> Vehicle
                        </Button>
                        <Button 
                            variant="contained"
                            startIcon={<SupervisorAccountIcon />}
                            sx={{
                                backgroundColor: '#4A95C5',
                            }}
                            onClick={() => handleLogin(false)}> Admin
                        </Button>
                    </Box>
                    <p className="signup-link" style={{ color: '#000' }}>
                        Don't have an account? 
                        <span onClick={handleSignUpClick} className="signup-text"> Sign Up</span>
                    </p>
                </Box>
               </div> 
            </div>
        </div>
    );
}

export default SignInPage;




/*
import React, { useState, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, InputAdornment, TextField, Button, Box, Typography, Checkbox, FormControlLabel, LinearProgress, Alert } from '@mui/material';
import { AccountCircle, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import ElectricalCarIcon from '@mui/icons-material/ElectricCar';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

// AuthContext ve Provider oluşturma
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorUsername, setErrorUsername] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const contextValue = {
        username, setUsername,
        password, setPassword,
        passwordVisible, setPasswordVisible,
        loading, setLoading,
        errorUsername, setErrorUsername,
        errorPassword, setErrorPassword,
        rememberMe, setRememberMe,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

// useAuth hook'u oluşturma
const useAuth = () => useContext(AuthContext);

function SignInPage() {
    const navigate = useNavigate();
    const {
        username, setUsername,
        password, setPassword,
        passwordVisible, setPasswordVisible,
        loading, setLoading,
        errorUsername, setErrorUsername,
        errorPassword, setErrorPassword,
        rememberMe, setRememberMe,
    } = useAuth();

    const handleSignUpClick = () => {
        navigate("/signUpPage");
    };

    const handleAdminClick = () => {
        navigate("/adminPage");
    };

    const handleVehicleClick = () => {
        navigate("/vehiclePage");
    };

    const handleLogin = (isVehicle) => {
        setLoading(true);
        setTimeout(() => setLoading(false), 5000);

        const loginData = {
            username: username,
            password: password,
        };

        fetch('https://test-api.robeff.com/api/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(loginData),
        })
            .then(response => response.json())
            .then(data => {
                if (data.access_token) {
                    localStorage.setItem('access_token', data.access_token);
                    localStorage.setItem('username', username);

                    if (rememberMe) {
                        sessionStorage.setItem('token', data.access_token);
                        sessionStorage.setItem('username', data.username);
                    }

                    if (isVehicle) {
                        handleVehicleClick();
                    } else {
                        handleAdminClick();
                    }

                } else {
                    alert('Login failed. Please check your username and password.');
                    setErrorUsername("Username doesn't exist.");
                    setErrorPassword("Password doesn't exist.");
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
                alert('An error occurred during login. Please try again.');
                setErrorUsername("Username must be filled.");
                setErrorPassword("Password must be filled.");
            });
    };

    return (
        <div className="login-container"
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100vh',
                padding: '0 20px',
                backgroundColor: '#2F3848'
            }}>
            <div className="login-content"
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginLeft: '250px'
                }}>
                <div className="logo-title-container">
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        marginBottom={3}
                        sx={{ position: 'relative', overflow: 'hidden' }}>
                        <img
                            src="/Logo.png"
                            alt="Logo"
                            style={{
                                width: 50,
                                height: 50,
                                marginRight: 10,
                                marginTop: 200
                            }}
                        />
                        <Typography
                            variant="h1"
                            component="h1"
                            align="center"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '3rem',
                                color: '#4A95C5',
                                marginBottom: 4,
                                marginTop: 28.5,
                                fontFamily: 'Arial, sans-serif',
                            }}
                        > CANWAY </Typography>
                    </Box>
                </div>
                <div className="login-form-container"
                    style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginLeft: '200px'
                    }}>
                    <Box
                        className="signup-box"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        p={4}
                        boxShadow={3}
                        borderRadius={2}
                        padding={2}
                        marginTop={2}
                        gap={2}
                        width="500px"
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.6)',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(255, 255, 255, 0.3)',
                            color: '#fff',
                        }}
                    >
                        <Typography
                            variant="h2"
                            component="h2"
                            align="center"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '2rem',
                                color: '#4A95C5',
                                marginBottom: 4,
                                marginTop: 4,
                            }}
                        > Sign in to your account </Typography>

                        <Box position="relative" width="500px" marginBottom={2}>
                            <TextField
                                label="Username"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircle />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errorUsername && (
                                <Alert severity="error" sx={{ marginTop: 1, zIndex: 1 }}>
                                    {errorUsername}
                                </Alert>
                            )}
                            {loading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '2px',
                                        backgroundColor: 'transparent',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#2F3848',
                                        },
                                        zIndex: 2,
                                    }}
                                />
                            )}
                        </Box>

                        <Box position="relative" width="500px" marginBottom={2}>
                            <TextField
                                label="Password"
                                type={passwordVisible ? 'text' : 'password'}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={passwordVisible ? 'Hide password' : 'Show password'}
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                            >
                                                {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errorPassword && (
                                <Alert severity="error" sx={{ marginTop: 1, zIndex: 1 }}>
                                    {errorPassword}
                                </Alert>
                            )}
                            {loading && (
                                <LinearProgress
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        left: 0,
                                        right: 0,
                                        height: '2px',
                                        backgroundColor: 'transparent',
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: '#2F3848',
                                        },
                                        zIndex: 2,
                                    }}
                                />
                            )}
                        </Box>

                        <Box display="flex" justifyContent="flex-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        sx={{
                                            color: '#2F3848',
                                            '&.Mui-checked': {
                                                color: '#2F3848',
                                            },
                                        }}
                                    />
                                }
                                label="Remember me"
                                sx={{
                                    color: '#000',
                                    marginLeft: -32.4,
                                }}
                            />
                        </Box>

                        <Button
                            onClick={() => handleLogin(true)}
                            variant="contained"
                            fullWidth
                            startIcon={<ElectricalCarIcon />}
                            sx={{
                                backgroundColor: '#2F3848',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#1F2838',
                                },
                            }}
                        > Vehicle </Button>

                        <Button
                            onClick={() => handleLogin(false)}
                            variant="contained"
                            fullWidth
                            startIcon={<SupervisorAccountIcon />}
                            sx={{
                                backgroundColor: '#4A95C5',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#3A84B5',
                                },
                            }}
                        > Admin </Button>

                        <Typography
                            variant="body2"
                            component="p"
                            align="center"
                            sx={{
                                color: '#333',
                                marginTop: 2,
                            }}
                        >
                            Don't have an account?
                            <Button
                                variant="text"
                                onClick={handleSignUpClick}
                                sx={{ color: '#333', textTransform: 'none' }}
                            > Sign Up </Button>
                        </Typography>
                    </Box>
                </div>
            </div>
        </div>
    );
}

const App = () => (
    <AuthProvider>
        <SignInPage />
    </AuthProvider>
);

export default App;
*/